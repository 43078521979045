var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"vo_add_transition",attrs:{"data-cy":"4097"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"data-cy":"4099"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addTransition)}}},[_c('v-card',{staticClass:"pa-4",attrs:{"data-cy":"4100"}},[_c('v-card-title',{attrs:{"data-cy":"4101"}},[_c('v-row',{attrs:{"data-cy":"4277"}},[_c('v-col',{staticClass:"col-md-auto",attrs:{"data-cy":"4102"}},[_c('span',{staticClass:"text-h6 font-weight-regular",attrs:{"data-cy":"4103"}},[_vm._v(_vm._s(_vm.$t("1441")))])]),_c('v-col',{staticClass:"text-end",attrs:{"data-cy":"4104"}},[_c('v-icon',{ref:"button_close_add_transition_dialog",attrs:{"data-cy":"4105","color":"lighten-1","medium":""},on:{"click":_vm.handleCloseAddTransitionDialog}},[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{attrs:{"data-cy":"4106"}},[_c('themis-input',{ref:"themis_input_from_status",attrs:{"data-cy":"4107","p-rules":_vm.fromStatusRule,"p-label":_vm._f("required")(("" + (_vm.$t('1442')))),"p-label-class":"secondary--text","p-name":"1442"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"transition_from_status",attrs:{"data-cy":"4108","item-text":"name","background-color":"white","color":"secondary","items":_vm.fromStatusesToDisplay,"placeholder":_vm.$t('1443'),"error-messages":errors,"hide-details":!errors.length,"disabled":_vm.fromAll,"outlined":"","multiple":"","return-object":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.fromStatuses),callback:function ($$v) {_vm.fromStatuses=$$v},expression:"fromStatuses"}}),_c('v-checkbox',{ref:"transition_from_all_checkbox",staticClass:"mb-4 pt-0",attrs:{"data-cy":"4109","color":"primary","input-value":_vm.fromAll,"disabled":!!_vm.fromStatuses.length,"hide-details":""},on:{"change":function($event){return _vm.handleFromAllCheckboxChange($event, errors)}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{ref:"transition_from_all_checkbox_label",staticClass:"grey--text text-lighten-2",attrs:{"data-cy":"4110"}},[_vm._v(_vm._s(_vm.$t("1444")))])]},proxy:true}],null,true)})]}}],null,true)}),_c('themis-input',{ref:"themis_input_to_status",attrs:{"data-cy":"4111","p-rules":"required","p-label":_vm._f("required")(("" + (_vm.$t('1445')))),"p-label-class":"secondary--text","p-name":"1445"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"transition_to_status",attrs:{"data-cy":"4112","background-color":"white","color":"secondary","item-text":"name","items":_vm.toStatusesToDisplay,"placeholder":_vm.$t('1446'),"error-messages":errors,"outlined":"","return-object":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.toStatus),callback:function ($$v) {_vm.toStatus=$$v},expression:"toStatus"}})]}}],null,true)}),(_vm.enableInputForTransitionName)?_c('themis-input',{ref:"themis_input_transition_name",attrs:{"data-cy":"4113","p-rules":("required|duplicate:" + _vm.isTransitionNameDuplicate + "|max:" + _vm.transitionNameCharacterLimit),"p-label":_vm._f("required")(("" + (_vm.$t('1447')))),"p-label-class":"secondary--text","p-name":"1447"},scopedSlots:_vm._u([(_vm.transitionsToDisplay.length)?{key:"append-label",fn:function(ref){
var color = ref.color;
return [_c('v-tooltip',{attrs:{"data-cy":"4114","max-width":"621","color":"grey darken-2","nudge-right":"180","bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1",attrs:{"data-cy":"4115","color":color,"small":""}},on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',{attrs:{"data-cy":"4116"}},[_vm._v(_vm._s(_vm.$t('1455')))])])]}}:null,{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [(_vm.isToStatusLinkedToTransition)?_c('v-combobox',{ref:"transition_name_combobox",attrs:{"data-cy":"4117","color":"secondary","background-color":"white","item-text":"name","item-value":"name","items":_vm.transitionsToDisplay,"placeholder":_vm.$t('1448'),"error-messages":errors,"readonly":_vm.disableTransitionName,"outlined":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.transition),callback:function ($$v) {_vm.transition=$$v},expression:"transition"}}):_c('v-text-field',{ref:"transition_name_text_field",attrs:{"data-cy":"4118","color":"secondary","background-color":"white","placeholder":_vm.$t('1448'),"error-messages":errors,"readonly":_vm.disableTransitionName,"outlined":""},on:{"focus":onFocus,"blur":onBlur},model:{value:(_vm.transition),callback:function ($$v) {_vm.transition=$$v},expression:"transition"}})]}}],null,true)}):_vm._e()],1),_c('v-card-actions',{staticClass:"justify-end pa-4",attrs:{"data-cy":"4119"}},[_c('v-btn',{ref:"button_cancel_add_transition",attrs:{"data-cy":"4120","text":""},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_vm._v(_vm._s(_vm.$t("1449")))]),_c('v-btn',{ref:"button_add_transition",staticClass:"ml-4",attrs:{"data-cy":"4121","color":"primary","disabled":_vm.disableSaveButton,"type":"submit"}},[_vm._v(_vm._s(_vm.$t("1450")))])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }