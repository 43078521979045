var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{ref:"status_card",staticClass:"pa-6 rounded-lg",attrs:{"data-cy":"4061"},on:{"click":_vm.onStatusClick}},[_c('v-row',{attrs:{"data-cy":"4062"}},[(_vm.hasFromAllTransitions)?_c('v-col',{staticClass:"col-auto mt-1",attrs:{"data-cy":"4063"}},[_c('v-tooltip',{attrs:{"data-cy":"4064","color":"grey darken-2","max-width":"400","offset-overflow":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({ref:"icon_from_all_transitions",attrs:{"data-cy":"4065","color":"grey darken-4"}},on),[_vm._v("mdi-merge")])]}}],null,false,2440950505)},[_c('span',{ref:"tooltip_from_all_transitions",attrs:{"data-cy":"4066"}},[_vm._v(_vm._s(_vm.$t("925")))])])],1):_vm._e(),_c('v-col',{staticClass:"text-truncate",class:{ 'pl-1': _vm.hasFromAllTransitions },attrs:{"data-cy":"4067"}},[_c('v-tooltip',{attrs:{"data-cy":"4068","color":"grey darken-2","max-width":"400","offset-overflow":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({ref:"status_name",staticClass:"text-h6 font-weight-regular",attrs:{"data-cy":"4069"}},on),[_vm._v(_vm._s(_vm.pStatusAlongWithTransitions.name))])]}}])},[_c('span',{ref:"tooltip_status",attrs:{"data-cy":"4070"}},[_vm._v(_vm._s(_vm.pStatusAlongWithTransitions.name))])])],1),_c('v-col',{staticClass:"col-auto pl-1 mt-1",attrs:{"data-cy":"4071"}},[(_vm.hasInitialTransition)?[_c('v-tooltip',{attrs:{"data-cy":"4072","color":"grey darken-2","max-width":"400","offset-overflow":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({ref:"icon_initial_transition",attrs:{"data-cy":"4073","color":"grey","small":""}},on),[_vm._v("mdi-circle")])]}}],null,false,3096381350)},[_c('span',{ref:"tooltip_initial_transition",attrs:{"data-cy":"4074"}},[_vm._v(_vm._s(_vm.$t("926")))])])]:_vm._e(),(_vm.hasNoIncomingTransitions)?[_c('v-tooltip',{attrs:{"data-cy":"4075","color":"grey darken-2","max-width":"400","offset-overflow":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({ref:"icon_no_incoming_transitions",attrs:{"data-cy":"4076","color":"warning"}},on),[_vm._v("mdi-alert")])]}}],null,false,1541538560)},[_c('span',{ref:"tooltip_no_incoming_transitions",attrs:{"data-cy":"4077"}},[_vm._v(_vm._s(_vm.$t("927")))])])]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }