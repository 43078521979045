import ThemisInput from "@/components/shared/input"
import { MAX_CHARACTER_LIMIT } from "@/constants"

export default {
  data() {
    return {
      status                  : null,
      category                : null,
      statusNameCharacterLimit: MAX_CHARACTER_LIMIT.STATUS_NAME
    }
  },
  props: {
    pCategories   : Array,
    pWorkflow     : Object,
    pIssueStatuses: Array
  },
  components: {
    ThemisInput
  },
  methods: {
    addStatus() {
      const statusToAdd = {
        id      : this.status.id,
        name    : this.status.name ?? this.status,
        category: this.category
      }

      if (!statusToAdd.id) {
        delete statusToAdd.id
      }
      this.$emit("addStatus", statusToAdd)
    },
    handleCloseAddStatusDialog() {
      this.$emit("closeDialog")
    },
    handleNewStatusInput(status) {
      this.status = status
    },
    handleStatusInputOnInputEvent() {
      if (this.status) {
        if (typeof this.status === "string" && !this.status.category) {
          const existingStatus = this.pIssueStatuses.find(status => status.name === this.status)
          if (existingStatus) {
            this.category = existingStatus.category
            this.status   = { ...existingStatus }
          }
        } else {
          this.category = this.status.category
        }
      }
    },
    handleCategoryInputOnInputEvent() {
      if (this.status && this.status.category && this.status.category !== this.category) {
        this.status = null
      }
    }
  },
  computed: {
    isStatusNameDuplicate() {
      return this.pWorkflow.statuses.some(status => status.name === this.status?.name || status.name === this.status)
    },
    statusesToDisplay() {
      return this.pIssueStatuses.filter(status => {
        return !this.pWorkflow.statuses.some(workflowStatus => workflowStatus.name === status.name
          || workflowStatus.id === status.id)
        && (!this.category || this.category === status.category)
      }).map(status => ({
        id      : status.id,
        name    : status.name,
        category: status.category
      }))
    }
  }
}