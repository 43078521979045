var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"vo_add_status",attrs:{"data-cy":"4122"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"data-cy":"4133"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addStatus)}}},[_c('v-card',{staticClass:"pa-4",attrs:{"data-cy":"4144"}},[_c('v-card-title',{attrs:{"data-cy":"4145"}},[_c('v-row',{attrs:{"data-cy":"4146"}},[_c('v-col',{staticClass:"col-md-auto",attrs:{"data-cy":"4147"}},[_c('span',{staticClass:"text-h6 font-weight-regular",attrs:{"data-cy":"4148"}},[_vm._v(_vm._s(_vm.$t("1471")))])]),_c('v-col',{staticClass:"text-end",attrs:{"data-cy":"4149"}},[_c('v-icon',{ref:"button_close_add_status_dialog",attrs:{"data-cy":"4150","color":"lighten-1","medium":""},on:{"click":_vm.handleCloseAddStatusDialog}},[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{attrs:{"data-cy":"4151"}},[_c('themis-input',{ref:"themis_input_status",attrs:{"data-cy":"4152","p-rules":("required|duplicate:" + _vm.isStatusNameDuplicate + "|max:" + _vm.statusNameCharacterLimit),"p-label":_vm._f("required")(("" + (_vm.$t('1472')))),"p-label-class":"secondary--text","p-name":"1472"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-combobox',{ref:"combobox_status",attrs:{"data-cy":"4153","color":"secondary","background-color":"white","item-text":"name","item-value":"name","items":_vm.statusesToDisplay,"placeholder":_vm.$t('1473'),"error-messages":errors,"outlined":""},on:{"focus":onFocus,"blur":onBlur,"input":_vm.handleStatusInputOnInputEvent},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})]}}],null,true)}),_c('themis-input',{ref:"themis_input_category",attrs:{"data-cy":"4154","p-rules":"required","p-label":_vm._f("required")(("" + (_vm.$t('1474')))),"p-label-class":"secondary--text","p-name":"1474"},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var onBlur = ref.onBlur;
var onFocus = ref.onFocus;
var errors = ref.errors;
return [_c('v-autocomplete',{ref:"autocomplete_category",attrs:{"data-cy":"4155","color":"secondary","background-color":"white","item-text":"name","item-value":"name","items":_vm.pCategories,"placeholder":_vm.$t('1475'),"error-messages":errors,"outlined":""},on:{"focus":onFocus,"blur":onBlur,"input":_vm.handleCategoryInputOnInputEvent},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"justify-end pa-4",attrs:{"data-cy":"4156"}},[_c('v-btn',{ref:"button_cancel_add_status",attrs:{"data-cy":"4157","text":""},on:{"click":function($event){return _vm.$emit('closeDialog')}}},[_vm._v(_vm._s(_vm.$t("1476")))]),_c('v-btn',{ref:"button_add_status",staticClass:"ml-4",attrs:{"data-cy":"4158","color":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.$t("1477")))])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }