import ThemisInput from "@/components/shared/input"
import { MAX_CHARACTER_LIMIT, ARRAY } from "@/constants"

export default {
  data() {
    return {
      fromStatuses                : [],
      fromAll                     : false,
      toStatus                    : null,
      transition                  : null,
      disableTransitionName       : false,
      transitionNameCharacterLimit: MAX_CHARACTER_LIMIT.TRANSITION_NAME
    }
  },
  props: {
    pIssueStatuses: Array,
    pWorkflow     : Object
  },
  components: {
    ThemisInput
  },
  methods: {
    handleCloseAddTransitionDialog() {
      this.$emit("closeDialog")
    },
    handleFromAllCheckboxChange(checkboxStatus) {
      this.fromAll = checkboxStatus
    },
    addTransition() {
      if (this.isTransitionNameUnderLimit) {
        const transitionToAdd = {
          id               : this.transition?.id,
          name             : this.transition?.name ?? this.transition,
          workflowId       : this.workflow.id,
          screenId         : null,
          initialTransition: false,
          transitionLinks  : this.fromStatuses.length
            ? this.fromStatuses.map(status => ({
              fromStatusId: status.id || status.name,
              toStatusId  : this.toStatus.id || this.toStatus.name
            }))
            : this.fromAll
              ? [{
                fromStatusId: null,
                toStatusId  : this.toStatus.id || this.toStatus.name
              }]
              : []
        }
        this.$emit("addTransition", transitionToAdd)
      }
    }
  },
  computed: {
    workflow() {
      return this.pWorkflow
    },
    workflowStatusesMap() {
      const workflowStatusesMap = new Map()
      for (const workflowStatus of this.workflow.statuses) {
        if (workflowStatus.id) {
          workflowStatusesMap.set(workflowStatus.id, workflowStatus)
        } else {
          workflowStatusesMap.set(workflowStatus.name, workflowStatus)
        }
      }
      return workflowStatusesMap
    },
    isToStatusLinkedToTransition() {
      for (const transition of this.workflow.transitions) {
        for (const transitionLink of transition.transitionLinks) {
          const statusName = this.workflowStatusesMap.get(transitionLink.toStatusId)?.name
          if (statusName === this.toStatus?.name
          || transitionLink.toStatusId === statusName
          ) {
            return true
          }
        }
      }
      return false
    },
    fromStatusesToDisplay() {
      if (this.toStatus) {
        return this.workflow.statuses.filter(workflowStatus => {
          return workflowStatus.name !== this.toStatus.name
        })
      }
      return this.workflow.statuses
    },
    fromStatusRule() {
      if (!this.fromAll) {
        return "required"
      }
    },
    toStatusesToDisplay() {
      if (this.fromStatuses.length) {
        const fromStatusNames = this.fromStatuses.map(status => status.name)
        return this.workflow.statuses.filter(status => {
          return !fromStatusNames.includes(status.name)
        })
      }
      return this.workflow.statuses
    },
    transitionsToDisplay() {
      if (this.toStatus) {
        return this.workflow.transitions.filter(transition => {
          return transition.transitionLinks.some(transitionLink => {
            const statusName = this.issueStatusesMap.get(transitionLink.toStatusId)?.name
            || this.workflow.statuses.find(status => status.name === transitionLink.toStatusId).name
            return statusName === this.toStatus.name && transitionLink.fromStatusId !== null
          })
        }).map(transition => ({ name: transition.name, id: transition.id }))
      }
    },
    /**
     * returns true if transition is reused when adding a new transition
     * and has the same from and to status
     */
    isTransitionReused() {
      const existingTransition = this.workflow.transitions?.find(transition => {
        const hasSameTransitionName = this.transition?.name
          ? transition.name === this.transition?.name
          : transition.name === this.transition

        const hasSameToStatus     = transition.transitionLinks[ARRAY.FIRST]?.toStatusId === this.toStatus?.id ||
          transition.transitionLinks[ARRAY.FIRST]?.toStatusId === this.toStatus?.name
        const hasSameFromStatuses = this.fromStatuses.every(fromStatus =>
          transition.transitionLinks?.some(transitionLink => transitionLink.fromStatusId === fromStatus.id ||
            transitionLink.fromStatusId === fromStatus.name)
        )
        return hasSameTransitionName && hasSameToStatus && hasSameFromStatuses
      })

      return !!existingTransition
    },
    disableSaveButton() {
      const hasFromStatusOrFromAll = this.fromStatuses.length || this.fromAll
      return hasFromStatusOrFromAll && this.toStatus && this.isTransitionNameUnderLimit &&
        this.isTransitionReused
    },
    issueStatusesMap() {
      const issueStatusesMap = new Map()
      this.pIssueStatuses.forEach(issueStatus => {
        issueStatusesMap.set(issueStatus.id, issueStatus)
      })
      return issueStatusesMap
    },
    isTransitionNameDuplicate() {
      return this.workflow.transitions.some(transition => {
        return transition.name === this.transition
      })
    },
    transitionsWithToStatus() {
      return this.workflow.transitions.filter(transition => {
        return transition.transitionLinks.some(transitionLink => {
          const statusName = this.issueStatusesMap.get(transitionLink.toStatusId)?.name
          return statusName === this.toStatus.name
        })
      }).map(transition => transition.name)
    },
    comboBoxSearchInputSync() {
      return this.transition?.name
        ? this.transition.name
        : this.transition
          ? this.transition
          : ""
    },
    isTransitionNameUnderLimit() {
      return this.transition?.name?.length <= this.transitionNameCharacterLimit ||
        this.transition?.length <= this.transitionNameCharacterLimit
    },
    enableInputForTransitionName() {
      return (this.fromStatuses.length || this.fromAll) && !!this.toStatus
    }
  },
  watch: {
    toStatus: {
      handler: function(newValue) {
        if (newValue) {
          if (typeof this.transition === "object" &&
            !this.transitionsWithToStatus.includes[this.transition?.name]) {
            this.transition = null
          }
          if (this.enableInputForTransitionName && this.fromAll) {
            this.transition            = `Transition to ${this.toStatus.name.toLowerCase()}`
            this.disableTransitionName = true
          }
        }
      }
    },
    enableInputForTransitionName: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          if (this.fromAll) {
            this.transition            = `Transition to ${this.toStatus.name.toLowerCase()}`
            this.disableTransitionName = true
          }
        } else {
          this.transition            = null
          this.disableTransitionName = false
        }
      }
    }
  }
}