import { mapGetters, mapActions, mapMutations } from "vuex"
import ThemisInput from "@/components/shared/input"
import ThemisWorkflowStatus from "@/components/workflow-status"
import ThemisTransitionAdd from "@/components/transition/add"
import ThemisStatusAdd from "@/components/workflow/status/add"
import ThemisWorkflowNavigateAway from "@/components/workflow/navigate-away"
import ThemisAlert from "@/components/shared/alert"
import ThemisDecision from "@/components/shared/decision"
import ThemisReadOnly from "@/components/shared/read-only"
import { ISSUE_STATUS_CATEGORY, MAX_CHARACTER_LIMIT, WORKFLOW_ROUTES } from "@/constants"
import { saveToLocalStorage, getFromLocalStorage } from "@/utils"
import { WORKFLOW } from "@/constants/bread-crumbs/workflow"
import { PAGE_TITLE_WITHOUT_TRANSLATION } from "@/constants/page-titles"

export default {
  name      : "Workflow",
  components: {
    ThemisInput,
    ThemisWorkflowStatus,
    ThemisTransitionAdd,
    ThemisStatusAdd,
    ThemisAlert,
    ThemisWorkflowNavigateAway,
    ThemisDecision,
    ThemisReadOnly
  },
  data() {
    return {
      localWorkflow                      : null,
      workflows                          : getFromLocalStorage("workflows"),
      categories                         : Object.values(ISSUE_STATUS_CATEGORY),
      showTransitionAddDialog            : false,
      showStatusAddDialog                : false,
      changedWorkflows                   : getFromLocalStorage("changedWorkflows"),
      workflowNameCharacterLimit         : MAX_CHARACTER_LIMIT.WORKFLOW_NAME,
      isWorkflowNameDuplicate            : false,
      isWorkflowNameFocused              : false,
      showWorkflowUnsavedChangesDialog   : false,
      destinationRoute                   : null,
      isNavigationAllowed                : false,
      showConfirmationDialogToSaveChanges: false
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!WORKFLOW_ROUTES.includes(to.name) &&
      this.changedWorkflows && this.changedWorkflows[this.workflow.id]) {
      this.destinationRoute = to
      if (!this.isNavigationAllowed) {
        this.showWorkflowUnsavedChangesDialog = true
        return
      }
    }
    next()
  },
  computed: {
    ...mapGetters({
      workflowsFromStore  : "workflows/workflows",
      issueStatuses       : "issueStatuses/issueStatuses",
      isWorkflowsEnabled  : "configurations/isWorkflowsEnabled",
      isUpdatingWorkflow  : "workflows/isUpdatingWorkflow",
      isWorkflowUpdated   : "workflows/isWorkflowUpdated",
      workflowUpdateError : "workflows/workflowUpdateError",
      issueTypes          : "issueTypes/issueTypes",
      isStatusUpdated     : "workflows/isStatusUpdated",
      hasStatusErrors     : "workflows/hasStatusErrors",
      workflowAssociations: "workflowAssociations/workflowAssociations"
    }),
    propsForAlertYes() {
      return {
        text    : this.$t("1451"),
        loading : this.showConfirmationDialogToSaveChanges ? false : this.isUpdatingWorkflow,
        disabled: !this.localWorkflow.name ||
        this.isWorkflowNameDuplicate ||
        !this.isWorkflowNameWithinLimit ||
        this.hasStatusNameErrorForCurrentWorkflow
      }
    },
    propsForAlertNo() {
      return {
        text: this.$t("1111")
      }
    },
    hasStatusNameErrorForCurrentWorkflow() {
      return this.hasStatusErrors.find(statusError => statusError.workflowId === this.workflow.id)?.statusNameError
    },
    isWorkflowChanged() {
      return this.changedWorkflows && this.changedWorkflows[this.workflow.id]
    },
    workflowsHelpCenterUrl() {
      return process.env.VUE_APP_WORKFLOWS_HELP_URL
    },
    workflow() {
      return this.workflows.find(workflow => workflow.id === +this.$route.params.id)
    },
    statusesAlongWithTransitions() {
      return this.workflow.statuses.map(status => {
        const transitions = this.workflow.transitions.filter(transition => {
          return transition.transitionLinks.some(transitionLink => {
            return transitionLink.toStatusId === status.id || transitionLink.toStatusId === status.name
          })
        })
        return {
          ...status,
          transitions
        }
      })
    },
    isWorkflowNameChanged() {
      return this.localWorkflow?.name !== this.workflowFromStore?.name
    },
    workflowFromStore() {
      return this.workflowsFromStore.find(workflow => workflow.id === this.workflow.id)
    },
    isWorkflowNameWithinLimit() {
      return this.localWorkflow.name?.length <= this.workflowNameCharacterLimit
    },
    isWorkflowAssociated() {
      return !!this.workflowAssociations.find(workflowAssociation =>
        workflowAssociation.workflowId === this.workflow.id)
    }
  },
  methods: {
    ...mapActions({
      updateWorkflow       : "workflows/updateWorkflow",
      revertChangedWorkflow: "workflows/revertChangedWorkflow",
      revertWorkflow       : "workflows/revertWorkflow",
      notify               : "shared/notify"
    }),
    ...mapMutations({
      setBreadcrumbs  : "shared/setBreadcrumbs",
      setPageTitle    : "shared/setPageTitle",
      setStatusUpdated: "workflows/setStatusUpdated"
    }),
    getStatusAlongWithTransitions(statusName) {
      return this.statusesAlongWithTransitions.filter(status => status.category === statusName)
    },
    handleYesForAlert() {
      if (this.isWorkflowAssociated) {
        this.showConfirmationDialogToSaveChanges = true
      } else {
        this.updateWorkflow({
          id  : this.workflow.id,
          data: this.changedWorkflows[this.workflow.id]
        })
      }
    },
    handleYesForAssociatedWorkflow() {
      this.updateWorkflow({
        id  : this.workflow.id,
        data: this.changedWorkflows[this.workflow.id]
      })
    },
    async handleNoForAlert() {
      await this.revertWorkflowChange()
      this.setBreadcrumbsForWorkflow()
    },
    async deleteChangedWorkflow() {
      const changedWorkflows = await this.revertChangedWorkflow({ id: this.workflow?.id })
      this.changedWorkflows  = changedWorkflows
    },
    async revertWorkflowChange() {
      await this.deleteChangedWorkflow()
      const updatedWorkflows = await this.revertWorkflow({ id: this.workflow?.id })
      this.workflows         = updatedWorkflows

      if (this.$route.name === "workflow-status-side-panel" && this.$route.query.status && !this.isNavigationAllowed) {
        this.$router.push({
          name  : "workflow",
          params: {
            id: this.workflow.id
          }
        })
      }
    },
    deleteChangedWorkflowName() {
      const copyChangedWorkflows = JSON.parse(JSON.stringify(this.changedWorkflows))
      if (copyChangedWorkflows && copyChangedWorkflows[this.workflow.id]) {
        delete copyChangedWorkflows[this.workflow.id].name
        if (!Object.keys(copyChangedWorkflows[this.workflow.id]).length) {
          delete copyChangedWorkflows[this.workflow.id]
        }
        saveToLocalStorage("changedWorkflows", copyChangedWorkflows)
        this.changedWorkflows = copyChangedWorkflows
      }
    },
    revertWorkflowNameChange() {
      this.deleteChangedWorkflowName()
      const workflowsCopy = this.workflows.map(workflow => {
        if (workflow.id === this.workflow.id) {
          workflow.name = this.workflowFromStore.name
        }
        return workflow
      })
      saveToLocalStorage("workflows", workflowsCopy)
      this.workflows = workflowsCopy
    },
    addTransitionToLocalStorage(transition) {
      let addedTransition
      let status

      const transitionId            = transition.id
      const existingTransition      = this.workflow.transitions?.find(transition =>
        transition.id === transitionId)
      const existingTransitionLinks = existingTransition ? existingTransition.transitionLinks : []

      const newTransitionLinksToBeAdded = transition.transitionLinks.filter(eachTransitionLink =>
        !existingTransitionLinks.some(transitionLink =>
          transitionLink.fromStatusId === eachTransitionLink.fromStatusId
          && transitionLink.toStatusId === eachTransitionLink.toStatusId)
      )
      transition.transitionLinks        = newTransitionLinksToBeAdded.length
        ? newTransitionLinksToBeAdded
        : transition.transitionLinks

      if (transitionId) {
        // construct to update the existing transition
        const workflowTransition = this.workflow.transitions.find(transition =>
          transition.id === transitionId)
        addedTransition          = {
          id               : workflowTransition.id,
          name             : workflowTransition.name,
          screenId         : workflowTransition.screenId,
          initialTransition: workflowTransition.initialTransition,
          transitionLinks  : transition.transitionLinks
        }

        const updatedWorkflows = this.workflows.map(eachWorkflow => {
          if (eachWorkflow.id === this.workflow.id) {
            eachWorkflow.transitions = eachWorkflow.transitions.map(workflowTransition => {
              if (workflowTransition.id === transitionId) {
                // pushing new transition links to existing transition
                workflowTransition.transitionLinks.push(...transition.transitionLinks)
                return workflowTransition
              }
              return workflowTransition
            })
          }
          return eachWorkflow
        })

        saveToLocalStorage("workflows", updatedWorkflows)
        this.workflows = updatedWorkflows
      } else {
        // construct the new transition to be added
        addedTransition = {
          name             : transition.name,
          screenId         : transition.screenId,
          initialTransition: transition.initialTransition,
          transitionLinks  : transition.transitionLinks
        }

        const updatedWorkflows = this.workflows.map(workflow => {
          if (workflow.id === this.workflow.id) {
            const newlyCreatedTransition = workflow.transitions.find(workflowTransition =>
              workflowTransition.name === transition.name && !workflowTransition.id
            )
            if (newlyCreatedTransition) {
              newlyCreatedTransition.transitionLinks.push(...transition.transitionLinks)
            } else {
              workflow.transitions.push(transition)
            }
          }
          return workflow
        })
        saveToLocalStorage("workflows", updatedWorkflows)
        this.workflows = updatedWorkflows
      }

      const changedWorkflows = getFromLocalStorage("changedWorkflows") || {}
      let changedWorkflow    = changedWorkflows?.[this.workflow.id]

      if (changedWorkflow?.transition) {

        // reusing existing transition when adding new transition
        const transitionContainingUpdate = changedWorkflow.transition.update
        const transitionContainingAdd    = changedWorkflow.transition.add

        if (transitionId && existingTransition) {
          if (!transitionContainingUpdate) {
            changedWorkflow.transition.update = []
          }
          const updateTransitionIndex   = changedWorkflow.transition.update.findIndex(
            workflowTransition => workflowTransition.id === addedTransition.id
          )
          const { id, transitionLinks } = this.getIdAndTransitionLinksFromTransition(transitionId)

          if (updateTransitionIndex >= 0) {
            // update the transition which is already present in update payload
            const transitionInUpdate = transitionContainingUpdate[updateTransitionIndex]

            // if transitionLinks is already present in update payload for the transition, then push the new transitionLinks
            // otherwise, create a empty transitionLinks array and push all the transitionLinks
            transitionInUpdate.transitionLinks
              ? transitionInUpdate.transitionLinks.push(...addedTransition.transitionLinks)
              : transitionInUpdate.transitionLinks = [...transitionLinks]
          } else {
            // different transition exists in update payload
            changedWorkflow.transition.update.push({
              id,
              transitionLinks
            })
          }
        } else {
          // add new transition
          if (!transitionContainingAdd) {
            changedWorkflow.transition.add = []
          }
          const localTransitionToUpdateIndex = changedWorkflow.transition.add.findIndex(transition =>
            transition.name === addedTransition.name
          )

          if (localTransitionToUpdateIndex >= 0) {
            const transitionToUpdate           = changedWorkflow.transition.add[localTransitionToUpdateIndex]
            transitionToUpdate.transitionLinks = [
              ...transitionToUpdate.transitionLinks,
              ...addedTransition.transitionLinks
            ]
          } else {
            changedWorkflow.transition.add.push(addedTransition)
          }

        }

      } else {
        // when changedWorkflow or changedWorkflow.transition is not present
        if (transitionId) {
          // add existing transition
          const { id, transitionLinks } = this.getIdAndTransitionLinksFromTransition(transitionId)

          changedWorkflow = {
            ...changedWorkflow,
            transition: {
              update: [{
                id,
                transitionLinks
              }]
            }
          }
        } else {
          // add new transition
          changedWorkflow = {
            ...changedWorkflow,
            transition: {
              add: [addedTransition]
            }
          }
        }
      }

      changedWorkflows[this.workflow.id] = changedWorkflow
      saveToLocalStorage("changedWorkflows", changedWorkflows)
      this.changedWorkflows = changedWorkflows

      this.showTransitionAddDialog = false
      if (typeof transition.transitionLinks[0].toStatusId === "string") {
        status = transition.transitionLinks[0].toStatusId
      } else {
        status = this.issueStatuses.find(status =>
          status.id === transition.transitionLinks[0].toStatusId)?.name
      }
      this.$router.push({
        name  : "transition",
        params: {
          id: this.workflow.id
        },
        query: {
          status,
          transitionName: transition.name
        }
      })
    },
    getIdAndTransitionLinksFromTransition(transitionId) {
      let existingTransitionFromWorkFlow

      for (const transition of this.workflow.transitions) {
        if (transition.id === transitionId) {
          existingTransitionFromWorkFlow = transition
        }
      }

      const { id, transitionLinks: oldTransitionLinks } = existingTransitionFromWorkFlow
      const transitionLinks                             = oldTransitionLinks.map(eachTransitionLink => {
        const transitionLink = {
          id          : eachTransitionLink.id,
          fromStatusId: eachTransitionLink.fromStatusId,
          toStatusId  : eachTransitionLink.toStatusId
        }
        if (!transitionLink.id) {
          delete transitionLink.id
        }
        return transitionLink
      })
      return { id, transitionLinks }
    },
    addStatusToLocalStorage(status) {
      const updatedWorkflows = this.workflows.map(workflow => {
        if (workflow.id === this.workflow.id) {
          workflow.statuses.push(status)
        }
        return workflow
      })
      saveToLocalStorage("workflows", updatedWorkflows)

      this.workflows = updatedWorkflows

      const changedWorkflows = getFromLocalStorage("changedWorkflows") || {}
      let changedWorkflow    = changedWorkflows?.[this.workflow.id]

      if (changedWorkflow?.status) {
        if (!changedWorkflow.status.add) {
          changedWorkflow.status.add = []
        }
        changedWorkflow.status.add.push(status)
      } else {
        changedWorkflow = {
          ...changedWorkflow,
          status: { "add": [status] }
        }
      }

      changedWorkflows[this.workflow.id] = changedWorkflow
      saveToLocalStorage("changedWorkflows", changedWorkflows)
      this.changedWorkflows = changedWorkflows

      this.showStatusAddDialog = false

      if (this.$route.name === "workflow-status-side-panel") {
        this.$router.push({
          name  : "workflow",
          params: {
            id: this.workflow.id
          }
        })
      }
    },
    onStatusClick({ name }) {
      if ((this.$route.name === "workflow" ||
      this.$route.name === "workflow-status-side-panel") &&
      decodeURI(this.$route.query.status) !== name) {
        this.$router.push({
          name  : "workflow-status-side-panel",
          params: {
            id: this.workflow.id
          },
          query: {
            status: name
          }
        })
      }
    },
    handleWorkflowNameInputOnBlurEvent(onBlur) {
      onBlur()
      this.isWorkflowNameFocused = false
      this.verifyAndUpdateWorkflowName()
      this.setBreadcrumbsForWorkflow()
    },
    handleWorkflowNameInputOnFocusEvent(onFocus) {
      onFocus()
      this.isWorkflowNameFocused = true
    },
    handleWorkflowNameInputOnEnter() {
      this.$refs.text_field_workflow_name.blur()
    },
    setBreadcrumbsForWorkflow() {
      const isWorkflowNameInputValid = !this.workflowUpdateError && this.localWorkflow?.name &&
        !this.isWorkflowNameDuplicate && this.isWorkflowNameWithinLimit
      if (isWorkflowNameInputValid) {
        this.setBreadcrumbs(
          WORKFLOW({ params: { id: this.localWorkflow.id } }, this.localWorkflow.name)
        )
        this.setPageTitle(PAGE_TITLE_WITHOUT_TRANSLATION(this.localWorkflow.name))
      }
    },
    verifyAndUpdateWorkflowName() {
      if (this.isWorkflowNameChanged) {
        const updatedWorkflows = this.workflows.map(workflow => {
          if (workflow.id === this.workflow.id) {
            workflow.name = this.localWorkflow.name
          }
          return workflow
        })
        saveToLocalStorage("workflows", updatedWorkflows)
        this.workflows = updatedWorkflows

        const changedWorkflows = getFromLocalStorage("changedWorkflows") || {}
        const changedWorkflow  = changedWorkflows?.[this.localWorkflow.id] || {}

        changedWorkflow.name               = this.localWorkflow.name
        changedWorkflows[this.workflow.id] = changedWorkflow
        saveToLocalStorage("changedWorkflows", changedWorkflows)
        this.changedWorkflows = changedWorkflows
      } else {
        this.revertWorkflowNameChange()
      }
    },
    handleContinueWithoutSaving() {
      this.showWorkflowUnsavedChangesDialog = false
      this.isNavigationAllowed              = true
      this.revertWorkflowChange()
    }
  },
  watch: {
    isWorkflowUpdated: {
      handler: function(newValue) {
        if (newValue) {
          this.deleteChangedWorkflow()
          this.notify({
            type: "success",
            text: "1438"
          })
          this.showConfirmationDialogToSaveChanges = false
        }
      }
    },
    isUpdatingWorkflow: {
      handler: function(newValue) {
        this.$DECISIONS.UPDATE_WORKFLOW.pActions[1].buttonProps.loading = newValue
      }
    },
    workflowUpdateError: {
      handler: function(newValue) {
        if (newValue) {
          this.revertWorkflowChange()
          this.notify({
            type: "error",
            text: "1439"
          })
        }
      }
    },
    workflow: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          const isLocalWorkflowNotSet          = !this.localWorkflow
          const isLocalWorkflowSetButDifferent = !isLocalWorkflowNotSet &&
            JSON.stringify(this.localWorkflow) !== JSON.stringify(newValue)
          if (isLocalWorkflowNotSet || isLocalWorkflowSetButDifferent) {
            this.localWorkflow = { ...newValue }
          }
        }
      }
    },
    "localWorkflow.name": {
      immediate: true,
      handler  : function(newValue) {
        if (this.isWorkflowNameDuplicate) {
          this.isWorkflowNameDuplicate = false
        } else {
          const duplicateWorkflow = this.workflows.find(
            workflow => workflow.name === newValue &&
            workflow.id !== this.localWorkflow.id
          )
          if (duplicateWorkflow) {
            this.isWorkflowNameDuplicate = true
          }
        }
      }
    },
    isStatusUpdated: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          this.workflows        = getFromLocalStorage("workflows")
          this.changedWorkflows = getFromLocalStorage("changedWorkflows")
          this.setStatusUpdated(false)
        }
      }
    },
    isNavigationAllowed: {
      handler: function(newValue) {
        if (newValue) {
          this.$router.push(this.destinationRoute)
        }
      }
    }
  }
}