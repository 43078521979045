export default {
  name: "WorkflowStatus",
  data: () => ({
  }),
  props: {
    pStatusAlongWithTransitions: Object
  },
  computed: {
    hasFromAllTransitions() {
      return this.pStatusAlongWithTransitions.transitions.some(transition =>
        !transition.initialTransition &&
        transition.transitionLinks.some(transitionLink => transitionLink.fromStatusId === null))
    },
    hasInitialTransition() {
      return this.pStatusAlongWithTransitions.transitions.some(transition => transition.initialTransition)
    },
    hasNoIncomingTransitions() {
      return !this.pStatusAlongWithTransitions.transitions.length
    }
  },
  methods: {
    onStatusClick() {
      this.$emit("statusClick", {
        id  : this.pStatusAlongWithTransitions.id,
        name: this.pStatusAlongWithTransitions.name
      })
    }
  }
}